<template>

  <transition name="infomodal">
    <div class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade">

        <div class="modal-container w-10/12 mt-5 mb-10 mx-auto rounded shadow-lg z-50 h-full bg-list-grey">
          <header class="h-32 p-4 pt-16 border-b border-gray-400 border-solid w-10/12 mx-auto">
            <i class="fas fa-times-circle cursor-pointer fixed right-0 items-center mt-0 rounded-2 mr-40 text-black text-4xl z-50"
              @click="$emit('close')" style="right:-12px;"></i>
              <h1 class="text-gray-600 font-bold text-4xl">{{title}}</h1>
        
          </header>
            <div class="h-screen overflow-y-auto w-10/12 mx-auto section-content ">
            <slot name="body">
                <div class="instruction pt-8 pb-8 text-sm">
                 <span v-html="content"></span>


              <div v-if="videoList.length > 0">
                <h3 class="text-gray-600 font-bold text-2xl  border-b border-gray-400 border-solid">Videos</h3>
                <div class="video mb-8 mt-4 flex flex-wrap"  v-for="video in videoList" :key="video.identifier" > 
                    <iframe class="sproutvideo-player w-1/2 h-64 pr-2 pb-2" v-bind:src="videoURL(video)" frameborder='0' scrolling="no"  allowfullscreen></iframe>
                </div>
              </div>

            <div v-if="diagramList.length > 0">
                <h3 class="text-gray-600 font-bold text-2xl  border-b border-gray-400 border-solid">Diagrams</h3>

               <div class="diagram mb-8 mt-4 flex flex-wrap" v-for="(pic, index) in diagramList" :key="pic.identifier" > 
                <dynamicimage :url="loadDiagram(pic)" :reference="'ref_'+index" :name="pic.title" class="w-1/2 h-64 pr-2 pb-2" ></dynamicimage>
              </div>
            </div>

        </div>

         

          
            </slot>
           <footer class="h-24 border-t border-gray-400 border-solid"> 
           </footer>

          </div>

        </div>
  
    </div>
  </transition>

</template>

<script>
  const dynamicimage = () => import('@/components/DynamicImage.vue')

export default {

  name: "infomodal",
  props: {
    title: {type: String, required: true},
    content: {type: String, required: true},
    videos: {type: Array, required: false},
     diagrams: {type: Array, required: false}
  },
   data() {
    return {
      videoList: this.videos == undefined ? [] : this.videos,
      diagramList: this.diagrams == undefined ? [] : this.diagrams,

    }
  },
  methods: {
    videoURL(video) {
      return (
        video.service_url_b.String +
        "?autoPlay=false&amp;playerTheme=dark&amp;playerColor="
      );
    },
    loadDiagram (diagram) {
        return diagram.folder + '/' + diagram.url
    },
  },
   components: {
    dynamicimage,
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.info-list li {
  @apply pb-4
}

.info-list li strong {
  @apply text-purple-700
}

</style>
